export const formatResponseFollowedItem = <T extends IResponseFollowedItemEntity>(item: IResponseFollowedItem<T>): IModelFollowedItem => {
  let imageUrl: string | undefined;
  let identitiesId: string[] | undefined;

  switch (item.type) {
    case "identity_item":
      if ("content" in item.entity && "PosterH" in item.entity.content)
        imageUrl = item.entity.content.PosterH;
      if ("identityId" in item.entity)
        identitiesId = item.entity.identityId;

      break;

    case "team":
      if ("logo" in item.entity)
        imageUrl = item.entity.logo;
      if ("identities" in item.entity)
        identitiesId = item.entity.identities?.map(identity => identity.type);

      break;

    default:
      break;
  }

  return {
    id: item.entity._id,
    title: item.entity.title,
    type: item.type,
    imageUrl,
    identitiesId,
  };
};

export const formatModelFollowedItem = (item: TFollowItemType, type: TFollowType): IModelFollowedItem => {
  return {
    id: item.id,
    title: item.title,
    type,
    imageUrl: item.imageUrl,
  };
};
